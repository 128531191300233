import axios from "axios";
const TON_API_BASEURL = "https://tonapi.io/v2"
let config = {
    headers: {
        Authorization: `Bearer ${process.env.APIV2_KEY}`
    }
}


// export const generateSchedule = ( async (owner,monk) => {
//     console.log("presale Wallet: ", owner);
//     const resData = await axios.post(`https://api.monaki.io/admin/tempFixedSchedule`,{
//         owner: owner,
//         total: monk
//     });
//     return resData.data;
// })

export const getNftsOwner = ( async (NftIds) => {
    const resData = await axios.post(`${TON_API_BASEURL}/nfts/_bulk`,{
        "account_ids": NftIds
    });
    return resData.data;
})

// presale transaction
export const presaleTransaction = ( async () => {
    const resData = await axios.get(`https://api.monaki.io/lia7tyfqfh68dfhb/startTransaction`);
    return resData.data;
})