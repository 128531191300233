import logo from './logo.svg';
import './App.css';
import { Routes, Route, useLocation  } from "react-router-dom";
import NftInfo from './pages/NftInfo';
import Presale from './pages/Presale';
// import Home from './pages/Home';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/64dfkhuhdffu984sd4" element={ <Presale /> } />
        </Routes>
    </div>
  );
}

export default App;
