import React, { useState }  from 'react'
import { presaleTransaction } from '../api/api';

const Presale = () => {
    const [response, setResponse] = useState();

    const start = () => {
        presaleTransaction().then((resData)=> {
            // setResponse()
            console.log(resData);
            setResponse(resData)
        })
    }

    return (
        <>
            <div>Presale</div>
            <button onClick={()=>start()}>Start Transaction</button><br/>
            { response && <> <span><br/> {JSON.stringify(response)}</span> </>}
        </>
    )
}

export default Presale